import { graphql, PageProps } from "gatsby"
import React, { memo } from "react"
import { useIntl, useWindowSize } from "~hooks"
import cls from "classnames"
import MemoLoader from "~~ux/Loader/Loader"

interface advocate {
  description: string[]
  email: string
  job: any
  image: string
  name: string
  position: string
  options: string[]
}

interface AvocatsTemplateProps extends PageProps {
  pageContext: { slug: string }
  data: {
    markdownRemark: {
      html: string
      frontmatter: {
        advocates: advocate[]
      }
    }
  }
}

const AvocatsTemplate: React.FC<AvocatsTemplateProps> = props => {
  const [intl] = useIntl()
  const {
    data: {
      markdownRemark: { frontmatter },
    },
  } = props
  const { advocates } = frontmatter
  const { toRender: isMobile } = useWindowSize(["mobile", "landscape"])

  if (!frontmatter)
    return (
      <MemoLoader className="absolute z-50 flex items-center justify-center h-screen min-w-full overflow-hidden transition-all bg-black-1" />
    )

  return (
    <div className="">
      <div
        className={isMobile ? "pt-[100px] text-left px-4" : "content mt-[25vh]"}
      >
        {advocates.map(advocate => {
          const { description, email, name, image, job, options } = advocate
          return (
            <div
              className={cls(!isMobile && "flex flex-col items-start")}
              key={name}
            >
              <div
                className={cls(
                  "grid",
                  !isMobile && "grid-cols-2   mb-[20px]"
                )}
              >
                <div>
                  <img src={image} />
                </div>
                <div
                  className={cls(
                    "flex flex-col text-text-black ",
                    !isMobile && "ml-[20px] "
                  )}
                >
                  <p
                    className={
                      isMobile
                        ? "font-bold text-[1.5rem] mt-[10px]"
                        : "font-bold text-[1.5rem]  leading-[normal]"
                    }
                  >
                    {name && intl(name).toUpperCase()}
                  </p>

                  {!job.includes("") && (
                    <p
                      className={
                        isMobile
                          ? "font-bold text-[1rem]"
                          : "font-bold text-[0.875rem]"
                      }
                    >
                      {job.map((jopPosition: any) => {
                        return <div> {intl(jopPosition)}</div>
                      })}
                    </p>
                  )}
                  <p className="text-[0.8rem]">
                    <a href={`mailto: ${email}`}>{email}</a>
                  </p>
                </div>
              </div>

              <div
                className={cls(
                  "text-text-black text-[0.875rem] font-content gap-x-[50px] textcols",
                  isMobile && "grid"
                )}
              >
                {!description.includes("") && (
                  <div>
                    {description.map((descrip: any) => {
                      return (
                        <div className={isMobile ? "mt-[20px] " : "mb-[20px] "}>
                          {intl(descrip)}
                        </div>
                      )
                    })}
                  </div>
                )}
                {!options.includes("") && (
                  <ul>
                    {options.map((option: any) => {
                      return (
                        <li
                          className={
                            option.length !== 0 ? "list-disc ml-[20px]" : ""
                          }
                        >
                          {intl(option)}
                        </li>
                      )
                    })}
                  </ul>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default memo(AvocatsTemplate)

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        advocates {
          description
          email
          job
          image
          name
          position
          options
        }
      }
    }
  }
`
